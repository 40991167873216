<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/something-else"/>
      <div class="content illustration illustration_shield">
        <div class="content__container">
          <p class="promo promo_blocks">{{ "confirm_payment" | localize }}</p>

          <div class="form__group">
            <v-autocomplete
            v-model="city"
            :items="cities"
            @blur="nextElementfocus('city', 'object')"
            @keyup.enter="$event.target.blur()"
            :class="{ invalid: $v.city.$dirty && !$v.city.required }"
            item-text="name"
            item-value="value"
            cache-items
            :label="'realty_city' | localize"
            :placeholder="'select' | localize"
            append-icon="icon-arrow-down"
            ref="city"
            outlined
            ></v-autocomplete>

            <v-select
              v-model="object"
              @change="nextElementfocus('object', 'price')"
              @blur="nextElementfocus('object', 'price')"
              @keyup.enter="$event.target.blur()"
              :items="getList('s2p4_select_1_value_', 4)"
              :class="{ invalid: $v.object.$dirty && !$v.object.required }"
              item-text="name"
              item-value="value"
              :label="'info_realty_type' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="object"
              outlined
            ></v-select>
          </div>

          <div class="form__group">
            <v-text-field
              v-model="price"
              class="input_number"
              inputmode="numeric"
              pattern="[0-9]*"
              @blur="nextElementfocus('price', 'capital')"
              @change="load_payments"
              @keyup.enter="$event.target.blur()"
              @keypress="onKeyPressHandler"
              @input="countPencent"
              @keyup="onKeyUpPriceHandler('price', price)"
              :class="{ invalid: $v.price.$dirty && !$v.price.required }"
              :hint="
                ($v.price.$dirty && !$v.price.required
                  ? 'form_reqiered'
                  : 'empty') | localize
              "
              :label="'s2p4_text_2' | localize"
              :placeholder="'s2p4_placeholder_1' | localize"
              append-icon="icon-₪"
              ref="price"
              outlined
            ></v-text-field>

            <div class="form__element">
              <v-text-field
                v-model="capital"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="$v.capital.$touch()"
                @keypress="onKeyPressHandler"
                @input="countPencent"
                @change="load_payments"
                @keyup="onKeyUpPriceHandler('capital', capital)"
                :class="{ invalid: $v.capital.$dirty && !$v.capital.required }"
                :hint="
                  ($v.capital.$dirty && !$v.capital.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :label="'s2p4_text_4' | localize"
                :placeholder="'example_text' | localize('1 000 000')"
                :message="percent"
                append-icon="icon-₪"
                ref="capital"
                outlined
              ></v-text-field>
              <div class="slider--ticks slider--ticks_mt" v-if="price && capital && percent">
                <span>{{ percent.toLocaleString($store.state.lang) }}{{ 's2p4_text_7' | localize }}</span>
              </div>
              </div>
          </div>

          <div v-if="price && capital && percent">
            <div class="error__container" v-if="amount < 50000">
              <p class="error">{{ 'mortgage_error_min_sum' | localize }} <span class="icon-₪"></span><br>{{ 'mortgage_error_enter' | localize }}</p>
            </div>

            <div class="error__container" v-else-if="financing > 75">
              <p class="error">{{ 'mortgage_error_percent' | localize }}<br>{{ 'mortgage_error_enter' | localize }}</p>
            </div>

            <div class="info info_large" v-else>
              <div>{{ 'mortgage_sum' | localize }}: {{ amount.toLocaleString() }} <span class="icon-₪"></span></div>
              <div>{{ 'fin_percent' | localize }}: {{ financing }} %</div>
            </div>
          </div>

          <br><br>

          <form @submit.prevent="submitHandler">
            <div class="form__group">
              <div class="form__element" :class="{ form__element_disabled: calc_type == '2'}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="payment"
                      class="input_number input_slider"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      v-bind="attrs" v-on="on"
                      @blur="$v.payment.$touch()"
                      @keypress="onKeyPressHandler"
                      :disabled="calc_type == '2'"
                      @keyup="onKeyUpPriceHandler('payment', payment)"
                      @change="syncSlider('payment', 'paymentSlider', true); calc_month()"
                      :class="{ invalid: $v.payment.$dirty && !$v.payment.required }"
                      :hint="
                        ($v.payment.$dirty && !$v.payment.required
                          ? 'form_reqiered'
                          : 'empty') | localize
                      "
                      :label="'s4p18_label_1' | localize"
                      :placeholder="'s4p18_placeholder_1' | localize"
                      append-icon="icon-₪"
                      ref="payment"
                      outlined
                    ></v-text-field>
                  </template>
                  <span>{{ "tt_payment" | localize }}</span>
                </v-tooltip>
                <v-slider
                  v-model="paymentSlider"
                  :min="min_payment"
                  :max="max_payment"
                  :disabled="calc_type == '2'"
                  @input="syncSlider('payment', 'paymentSlider'); calc_month()"
                  class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                  <span>{{ min_payment.toLocaleString() }}</span>
                  <span>{{ max_payment.toLocaleString() }}</span>
                </div>
              </div>
            </div>

            <v-radio-group v-model="calc_type" row>
              <v-radio :label="'calc_payment' | localize" value="1" on-icon="icon-radio-on" off-icon="icon-radio-off"></v-radio>
              <v-radio :label="'calc_month' | localize" value="2" on-icon="icon-radio-on" off-icon="icon-radio-off"></v-radio>
            </v-radio-group>

            <div class="form__group">
              <div class="form__element" :class="{ form__element_disabled: calc_type == '1'}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="month"
                      class="input_number input_slider"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      v-bind="attrs" v-on="on"
                      :disabled="calc_type == '1'"
                      @blur="$v.month.$touch()"
                      @keypress="onKeyPressHandler"
                      @change="syncSlider('month', 'monthSlider', true); calc_payment()"
                      :class="{ invalid: $v.month.$dirty && !$v.month.required }"
                      :hint="
                        ($v.month.$dirty && !$v.month.required
                          ? 'form_reqiered'
                          : 'empty') | localize
                      "
                      :label="'month_payment' | localize"
                      placeholder="120"
                      ref="month"
                      outlined
                    ></v-text-field>
                  </template>
                  <span>{{ "tt_month" | localize }}</span>
                </v-tooltip>
                <v-slider
                  v-model="monthSlider"
                  :min="min_month"
                  :max="max_month"
                  :disabled="calc_type == '1'"
                  @input="syncSlider('month', 'monthSlider'); calc_payment()"
                  class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                  <span>{{ min_month.toLocaleString() }}</span>
                  <span>{{ max_month.toLocaleString() }}</span>
                </div>
              </div>

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_formatted"
                      class="v-input-calendar input_number"
                      v-mask.hide-on-empty="dateMask"
                      @focus="date = parseDate(date_formatted)"
                      @blur="date = parseDate(date_formatted); $v.date.$touch()"
                      :class="{ invalid: ($v.date.$dirty && !$v.date.required) || ($v.date.$dirty && !$v.date.validDate) }"
                      :hint="hintArray($v.date, [{rule: $v.date.required, message: 'form_reqiered'}, {rule: $v.date.validDate, message: 'form_wrong_date'}]) | localize"
                      :label="'s1p2_text' | localize"
                      :placeholder="'s1p2_date' | localize"
                      outlined
                      persistent-hint
                      append-icon="icon-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @change="checkForm($v.date)"
                  no-title
                  full-width
                  :min="dateFrom"
                  @input="menu = false; date_formatted = formatDate(date)"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="pricetexts">
              <div class="pricetext">
                <div class="pricetext__icon pricetext__icon_1"></div>
                <p>
                  {{ "s4p18_text_2_1" | localize }}
                  {{ min_payment.toLocaleString() }} <br />
                  {{ "s4p18_text_2_2" | localize }}
                </p>
              </div>
              <div class="pricetext" v-if="recommendation">
                <div class="pricetext__icon pricetext__icon_2"></div>
                <p>
                  {{ "s4p18_text_3_1" | localize }}
                  {{ recommendation.toLocaleString() }} <br />
                  {{ "s4p18_text_3_2" | localize }}
                </p>
              </div>
              <div class="pricetext">
                <div class="pricetext__icon pricetext__icon_3"></div>
                <p>
                  {{ "s4p18_text_4_1" | localize }}
                  {{ max_payment.toLocaleString() }} <br />
                  {{ "s4p18_text_4_2" | localize }}
                </p>
              </div>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
              <p v-else class="info info_3">
                {{ "payment_text_1" | localize }}
              </p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'
import { formMixin } from '@/mixins/formMixin.js'

export default {
  name: 'Mortgage_payment',
  components: { Backlink },
  mixins: [clientMixin, numberMixin, saveMixin, formMixin, commonMixin],
  data: () => ({
    month: null,
    payment: null,
    monthSlider: null,
    paymentSlider: null,
    calc_type: null,
    min_payment: 1200,
    recommendation: null,
    max_payment: 34000,
    min_month: 60,
    max_month: 360,
    success: false,
    userServiceId: null,
    userId: null,
    date: null,
    dateFrom: new Date().toISOString().substr(0, 10),
    date_formatted: null,
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    menu: false,
    error: null,

    cities: [],
    city: null,
    price: null,
    capital: null,
    object: null,

    percent: null,
    amount: null,
    financing: null
  }),
  created () {
    const mortgage = this.$cookies.get('mortgage') || {}
    this.date = mortgage.date || null
    this.date_formatted = this.formatDate(mortgage.date || new Date().toISOString().substr(0, 10))
    this.userId = this.$store.state.user ? this.$store.state.user.id : 0
    this.calc_type = mortgage.calc_type || '1'
    this.date = mortgage.date || null
    this.paymentSlider = mortgage.payment || this.min_payment
    this.payment = this.formatNumber(this.paymentSlider)
    this.calc_month()
    this.load_payments()

    this.price = mortgage.price ? this.formatNumber(mortgage.price) : null
    this.capital = mortgage.capital ? this.formatNumber(mortgage.capital) : null
    this.object = mortgage.object || null
    this.city = mortgage.city || null
    this.countPencent()
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch((e) => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  validations: {
    month: { required },
    date: { required, validDate: v => formMixin.methods.isValidDate(v) },
    price: { required },
    city: { required },
    payment: { required },
    capital: { required },
    object: { required }
  },
  methods: {
    load_payments () {
      const mortgage = this.$cookies.get('mortgage') || {}
      this.$store.commit('loading', true)
      this.axios.get('user_service', {
        params: {
          user_id: this.userId,
          service_id: this.$store.state.services.refMortgage
        }
      }).then((response) => {
        this.userServiceId = response.data.id || 0
        this.axios.get('program/payments', {
          params: {
            user_service_id: this.userServiceId,
            price: mortgage.price,
            capital: mortgage.capital
          }
        }).then((response) => {
          this.min_payment = response.data.min_payment
          this.recommendation = response.data.recommendation
          this.max_payment = response.data.max_payment
          this.min_month = response.data.min_month
          this.max_month = response.data.max_month
          this.paymentSlider = mortgage.payment || this.min_payment
          this.payment = this.formatNumber(this.paymentSlider)
          this.calc_month()
          this.success = true
          this.$store.commit('loading', false)
        }).catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
    calc_month () {
      const percent = (this.parseNumber(this.payment) - this.min_payment) * 100 / (this.max_payment - this.min_payment)
      this.month = this.monthSlider = Math.round(this.max_month - ((this.max_month - this.min_month) * percent / 100))
    },
    calc_payment () {
      const percent = (this.month - this.min_month) * 100 / (this.max_month - this.min_month)
      this.paymentSlider = Math.round(this.max_payment - ((this.max_payment - this.min_payment) * percent / 100))
      this.payment = this.formatNumber(this.paymentSlider)
    },
    countPencent () {
      if (this.capital && this.price) {
        const capital = this.parseNumber(this.capital)
        const price = this.parseNumber(this.price)
        this.percent = Math.round((capital * 100) / price)
        this.amount = Math.round(price - capital)
        this.financing = Math.round((this.amount * 100) / price)
      }

      this.checkForm()

      if (this.amount < 50000 || this.financing > 75) {
        this.success = false
      }
    },
    submitHandler () {
      this.price = this.parseNumber(this.price)
      this.capital = this.parseNumber(this.capital)
      this.payment = this.parseNumber(this.payment)
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$scrollTo('.step', 300, { offset: -150 })
        this.success = false
        return
      }

      const mortgage = this.$cookies.get('mortgage') || {}
      mortgage.date = this.date
      mortgage.calc_type = this.calc_type
      mortgage.payment = this.payment
      mortgage.price = this.price
      mortgage.capital = this.capital
      mortgage.city = this.city
      mortgage.object = this.object
      this.$cookies.set('mortgage', mortgage)
      const saveObj = this.calc_type === '1' ? { amount: this.amount, payment: parseInt(this.payment, 10), months: null } : { amount: this.amount, payment: null, months: parseInt(this.month, 10) }
      this.saveServiceInfo('mortgage', false, {
        date: this.date,
        mortgage_city_id: this.city,
        realty_type: this.object,
        mortgage_realty_price: mortgage.price,
        mortgage_capital: mortgage.capital
      })
      this.saveUserService('mortgage', '/mortgage/bank-offers', saveObj)
    }
  }
}
</script>
